//const URL_API = 'http://localhost:8888/marsso-api'; // LOCAL
const URL_API = 'https://marsso-paris.com/api'; // PROD
// const URL_API = URL_API_LOCAL;
const URL_PAYMENT = '/payment.php';
const URL_EMAIL = '/send-email.php';


const API = {
    async sendPayment(formData) {
        try {
            const resp = await fetch(URL_API+URL_PAYMENT, {
              method: "POST",
              body: formData,
            });
      
            const json = await resp.json();
      
            window.Payplug.showPayment(json.url);
            return true;
          } catch (e) {
            console.error(e);
            return false;
          }

    },

    // body = string JSON
    async sendEmail(type, body) {
        const jsonBody = JSON.parse(atob(body));
        const formData = new FormData();
        formData.append('material', jsonBody.material);
        formData.append('email', jsonBody.email);
        formData.append('size', jsonBody.size);
        formData.append('name', jsonBody.name);
        formData.append('price', jsonBody.price);
        formData.append('type', type);

        try {
            const resp = await fetch(URL_API+URL_EMAIL, {
              method: "POST",
              body: formData
            });
            await resp.json();
          } catch (e) {
            return false;
          }

          return true;
    },
}

export default API;